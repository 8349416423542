import { User } from "../../models/shared";
import { AdaptiveChannel, DeliveryChannel } from "../channels/channel";
import { ZmEvent } from "../events/event";
import { DashboardConfigOptions } from "src/app/services/graphs.service";
import moment from "moment";

export type IncidentObject = {
    created_at?: string;
    incident_id?: number;
    object_id: number;
    object_type: string;
    updated_at?: string;
};

export class Incident {
    _frontData: {
        duration: string;
        durationSort: moment.Duration;
        runtime_state: string;
        triggering_object_name: string;
        triggering_object_type: string;
        root_cause_rule: string;
        number_of_objects?: null;
        start_time: moment.Moment;
        end_time?: moment.Moment;
    };
    objects: IncidentObject[];
    objectsCount: number;
    created_at: string;
    customer_id: number;
    id: number;
    likely_cause: string;
    name: string;
    start_time: string;
    end_time: string;
    state: string;
    is_zixi: boolean;
    triggering_event_id: string;
    triggering_error_code: string;
    triggering_error_group: string;
    triggering_error_message: string;
    triggering_error_short_message: string;
    triggering_object_id: number | null;
    triggering_object_name: string;
    triggering_object_type: string | null;
    updated_at: string;
    createdBy: User;
    created_by_user_id: number;
    modified_by_user_id: number;
    lastModifiedBy: User;
    configuration: IncidentConfig;
    hasFullDetails?: boolean;
    is_public: boolean;
    is_v2: boolean;
    last_active: Date | null;
    error_identifier: string | null;
}
export class IncidentConfig {
    // OLD
    pinnedEvents?: ZmEvent[];
    graphs?: DashboardConfigOptions;
    selectedChannel?: AdaptiveChannel | DeliveryChannel;
    selectedObjectIDs?: string[];
    // NEW
    flexGraphs?: any[];
    rca?: {
        correctCause?: boolean;
        likelyCause?: string;
        describeRootCause?: string;
    };
}
